import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>Nossa missão</Subheading>}
        heading="Nós fazemos parte da revolução do agro."
        description= "A Safraí nasceu com a missão de otimizar os processos do agro negócio, seja a tomada de crédito, emissão de CPR ou conexão entre revendas e produtores rurais. Utilizamos tecnologia de ponta com inteligência artificial e mais de 2000 fontes de dados para diminuir riscos e acelerar o processo de decisão."
        buttonRounded={true}
        primaryButtonText="Veja nossos serviços"
        primaryButtonUrl="/pricing"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Nossa visão</Subheading>}
        heading="Nosso foco é entregar o melhor para o setor."
        description= "Nascemos com o propósito de sermos a melhor plataforma para o setor agro, acreditamos que desde o pequeno produtor até a agroindustria deveria ter acesso as melhores soluções. Somos facilitadores para poder conectar as pontas para produzir mais e gerar valor."
        buttonRounded={true}
        primaryButtonText="Fale conosco"
        primaryButtonUrl="/contactus"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <Features
        subheading={<Subheading>Nossos valores</Subheading>}
        heading="É isso que nos guia."
        description="Nossos valores é o que nos guiam para fazer mais e melhor, sempre buscando a excelência nos serviços para os nossos clientes com foco na solução do problema."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "Suporte para todos",
            description: "Não importa se você é pequeno ou grande produtor, se é uma revenda local ou uma agro industria. Nós estamos aqui para atender a todos."
          },
          {
            imageSrc: ShieldIconImage,
            title: "Experiência do time Safraí",
            description: "Sabemos onde queremos chegar e nossa bagagem ajuda a trazer as melhores soluções tecnológicas para o mercado, ajudando a todos a produzir mais."
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Satisfação do cliente",
            description: "Sua satisfação é o nosso combustível para inovarmos e gerar mais valor para o setor agro. Todos os feedbacks coletados nos ajudam a melhorar dia após dia."
          },
        ]}
        linkText=""
      />
      <Footer />
    </AnimationRevealPage>
  );
};
