import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-lg`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:
        "https://s2.glbimg.com/CZR_KfCbBQbjk2Jqu3jcRG2jLJA=/e.glbimg.com/og/ed/f/original/2022/07/20/gettyimages-1323264565.jpg",
      subtitle: "Consiga crédito hoje",
      title: "Produtores rurais",
      description:
        "Na Safraí, entendemos as necessidades do produtor rural. Com nossa solução de crédito 100% online, proporcionamos rapidez e segurança na obtenção de financiamento. Utilizamos inteligência artificial para decisões de crédito mais precisas, garantindo velocidade em operações barter. Emitimos a CPR automaticamente em segundos e você só terá o trabalho de escolher o que precisa para cultivar.",
      url: "#"
    },

    {
      imageSrc:
        "https://terramagna.com.br/wp-content/uploads/2022/08/Insumos-carregados-distribuidor.jpg",
      subtitle: "Todo o ciclo de crédito em suas mãos",
      title: "Revendas agrícolas",
      description:
        "Para as revendas agrícolas, a Safraí é mais do que uma plataforma. É uma ferramenta que garante transações seguras e confiáveis. Ao receber a CPR dos produtores, proporcionamos um sistema de monitoramento de garantias e recebíveis. Assim revendedores, tradings ou cooperativas podem focar no que realmente importa: vender.",
      url: "#"
    },

    {
      imageSrc:
        "https://www.siagri.com.br/wp-content/uploads/2022/01/gestao-da-agroindustria.jpg",
      subtitle: "Conheça melhor seu cliente",
      title: "Agroindústrias",
      description:
        "Nossa colaboração com as agroindústrias é essencial. A Safraí garante um fluxo constante de insumos para revendas, mantendo a cadeia de suprimentos em movimento. Com nossa tecnologia, otimizamos operações, riscos e garantimos que as agroindústrias possam fornecer com eficiência e confiança.",
      url: "#"
    },
    {
      imageSrc:
        "https://gestagro360.com.br/wp-content/uploads/2021/07/FMC-e-TerraMagna-disponibilizam-R100-milhoes-para-o-agro-foto.jpg",
      subtitle: "Diminua a inadimplência",
      title: "Instituições financeiras",
      description:
        "No coração da Safraí está a nossa parceria com instituições financeiras. Proporcionamos uma plataforma onde o crédito é acessível para todos na cadeia agrícola. Através da nossa abordagem inovadora, as instituições podem emprestar com confiança, sabendo que nossas soluções de inteligência artificial estão trabalhando para minimizar riscos.",
      url: "#"
    }

  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Nunca foi tão simples emitir crédito agro</HeadingTitle>
          <HeadingDescription>
            Nós trabalhamos para entregar o máximo de inteligência de dados, agilidade e processos ajustados para cada cliente, reduzindo inadimplência, aumentando a eficiência e gerando valor para o segmento agro.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Veja mais</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
