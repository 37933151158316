import React from "react";
import GlobalStyles from './styles/GlobalStyles';  // Certifique-se de que o caminho está correto
import ComponentRenderer from "./ComponentRenderer.js";  // Certifique-se de que o caminho está correto
import MainLandingPage from "./MainLandingPage.js";  // Certifique-se de que o caminho está correto
import ThankYouPage from "./ThankYouPage.js";  // Certifique-se de que o caminho está correto
import ContactUs from "pages/ContactUs";
import Pricing from "pages/Pricing";
import Partnership from "pages/Partnership";
import Login from "pages/Login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Signup from "pages/Signup";
import AboutUs from "pages/AboutUs";



export default function App() {
  return (
    <>
      <GlobalStyles />
      <Router>
        <Routes>
          <Route path="/components/:type/:subtype/:name" element={<ComponentRenderer />} />
          <Route path="/components/:type/:name" element={<ComponentRenderer />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/" element={<MainLandingPage />} />
          <Route path="/Partnership" element={<Partnership />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/aboutus" element={<AboutUs />} />
        </Routes>
      </Router>
    </>
  );
}
