import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/partnership-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500 placeholder-gray-800`;



const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Seja nosso parceiro",
  heading = <>Indique clientes para a <span tw="text-primary-500">Safraí</span><wbr/> e seja remunerado.</>,
  description = "Nosso programa de indicação você consegue trabalhar sua carteira de clientes e faturar muito",
  submitButtonText = "Enviar",
  formAction = "#",
  formMethod = "get",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form action={formAction} method={formMethod}>
            <Input type="text" name="CompanyName" placeholder="Nome da empresa" required/>
            <Input type="text" name="CNPJ" placeholder="CNPJ" required/>
            <Input type="text" name="name" placeholder="Nome completo" required/>
            <Input type="text" name="phone" placeholder="Whatsapp com DDD" required/>
            <Input type="email" name="email" placeholder="Insira seu email" required/>
            <select tw="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500" name="productiveArea" required>
              <option value="" disabled selected>Segmento de atuação</option>
              <option value="propriedade_rural">Propiedade Rural</option>
              <option value="revenda_agricola">Revenda agrícola</option>
              <option value="pesquisa_estudo">Pesquisa ou estudo</option>
              <option value="contabil">Escritório contábil</option>
              <option value="advocacia">Escritório de advocacia</option>
              <option value="consultoria">Consultoria</option>
              <option value="cia_grupo_agricola">Companhia ou grupo agrícola</option>
              <option value="cooperativa">Cooperativa</option>
            </select>
              <select tw="mt-6 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500" name="state" required>
                <option value="" disabled selected>Estado de atuação</option>
                <option value="AC">Acre</option>
                <option value="AL">Alagoas</option>
                <option value="AP">Amapá</option>
                <option value="AM">Amazonas</option>
                <option value="BA">Bahia</option>
                <option value="CE">Ceará</option>
                <option value="DF">Distrito Federal</option>
                <option value="ES">Espírito Santo</option>
                <option value="GO">Goiás</option>
                <option value="MA">Maranhão</option>
                <option value="MT">Mato Grosso</option>
                <option value="MS">Mato Grosso do Sul</option>
                <option value="MG">Minas Gerais</option>
                <option value="PA">Pará</option>
                <option value="PB">Paraíba</option>
                <option value="PR">Paraná</option>
                <option value="PE">Pernambuco</option>
                <option value="PI">Piauí</option>
                <option value="RJ">Rio de Janeiro</option>
                <option value="RN">Rio Grande do Norte</option>
                <option value="RS">Rio Grande do Sul</option>
                <option value="RO">Rondônia</option>
                <option value="RR">Roraima</option>
                <option value="SC">Santa Catarina</option>
                <option value="SP">São Paulo</option>
                <option value="SE">Sergipe</option>
                <option value="TO">Tocantins</option>
                <option value="mais_estados">Mais de um estado</option>
            </select>
            <div tw="mt-6">
                <label tw="block text-sm font-medium">Qual a maior necessidade dos seus clientes?</label>
                <div tw="mt-2 space-y-4">
                    <div>
                        <input type="checkbox" name="client_necessity" value="analise_credito" id="analise_credito" tw="rounded border-gray-300 text-primary-500 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50" />
                        <label htmlFor="analise_credito" tw="ml-2 text-sm">Análise de crédito</label>
                    </div>
                    <div>
                        <input type="checkbox" name="client_necessity" value="busca_credores" id="busca_credores" tw="rounded border-gray-300 text-primary-500 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50" />
                        <label htmlFor="busca_credores" tw="ml-2 text-sm">Busca de credores e aprovação de crédito</label>
                    </div>
                    <div>
                        <input type="checkbox" name="client_necessity" value="confeccao_cpr" id="confeccao_cpr" tw="rounded border-gray-300 text-primary-500 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50" />
                        <label htmlFor="confeccao_cpr" tw="ml-2 text-sm">Confecção e registro de CPR</label>
                    </div>
                    <div>
                        <input type="checkbox" name="client_necessity" value="cobranca" id="cobranca" tw="rounded border-gray-300 text-primary-500 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50" />
                        <label htmlFor="cobranca" tw="ml-2 text-sm">Cobrança</label>
                    </div>
                    <div>
                        <input type="checkbox" name="client_necessity" value="gestao_clientes" id="gestao_clientes" tw="rounded border-gray-300 text-primary-500 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50" />
                        <label htmlFor="gestao_clientes" tw="ml-2 text-sm">Gestão da carteira de clientes</label>
                    </div>
                    <div>
                        <input type="checkbox" name="client_necessity" value="gestao_fazenda" id="gestao_fazenda" tw="rounded border-gray-300 text-primary-500 shadow-sm focus:border-primary-300 focus:ring focus:ring-offset-0 focus:ring-primary-200 focus:ring-opacity-50" />
                        <label htmlFor="gestao_fazenda" tw="ml-2 text-sm">Gestão da fazenda</label>
                    </div>
                </div>
            </div>
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
