import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import Parceiros from "components/forms/Parceiros";
import TwoColSingleFeatureWithStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import ThreeColSimple from "components/features/ThreeColSimple";

const HighlightedText = tw.span`text-primary-500`

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Parceiros />
      <TwoColSingleFeatureWithStats />
      <ThreeColSimple/>
      <MainFeature
        heading={<>Agro feito por <HighlightedText>profissionais do agro</HighlightedText></>}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
