import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  subheading = "Perguntas frequentes",
  heading = "Tem alguma dúvida?",
  description = "Nós temos respostas para todas elas. Veja nosso banco de perguntas frequentes e se não encontrar o que procura, entre em contato conosco.",
  faqs = [
    {
      question: "O que é a Safraí?",
      answer:
        "Safraí é uma plataforma que concentra todo o ciclo de crédito agro em um único lugar. Aliamos tecnologia com inteligência artificial e mais de 2000 fontes de dados para fazer a melhor análise de crédito com o mínimo de risco, emitimos a CPR para o produtor rural e conectamos com revendedores parceiros para que façam negócios e prosperem juntos. Para as revendas, além da conexão com os produtores, disponibilizamos também a análise de crédito para tomarem a melhor decisão em relação a um cliente. Fornecemos também o monitoramento de garantias e gestão de recebíveis para a revenda focar no que importa: vender. "
    },
    {
      question: "Como funciona?",
      answer:
        "Simples. Faça um cadastro simples para entendermos suas neessidades. Acesse a plataforma e comece a usar."
    },
    {
      question: "É possível tomar crédito sem garantia?",
      answer:
        "No momento, a Safraí auxilia a tomada de crédito com garantia de CPR. As operações barter geralmente são travadas por meio de Cédula de Produto Rural (CPR), o que permite que o agricultor possa retirar rapidamente insumos para aplicação em suas lavouras, sem que isso implique em demorados processos a cada nova compra de produtos. E na Safraí você consegue fazer todo o processo de forma automática e sem enrolação."
    },
    {
      question: "Em quanto tempo meu crédito está liberado?",
      answer:
        "Tudo vai depender se a sua documentação está em dia e em conformidade com os orgãos compententes. Se estiver tudo certo, a análise e emissão de CPR sai em minutos e a liberação acontece no mesmo dia."
    },
    {
      question: "Qual o limite máximo de liberação de crédito na Safraí?",
      answer:
        "Nós não emitimos o crédito, nossa plataforma gera inteligência para a melhor decisão de emissão de crédito e conectamos com fornecedores agrícolas que querem te dar crédito. Se você é produtor você conseguirá avaliar seu perfil e emitir a CPR para ir a mercado comprar insumos para sua produção. Se você é fornecedor agrícola você consegue avaliar seus clientes para tomar a melhor decisão e mitigar riscos. Lembre-se: cada análise de crédito é única, e tudo vai depender da capacidade da sua fazenda de absorver crédito. Portanto a decisão de limite de crédito está com o fornecedor que quer te emprestar."
    },
    {
      question: "Sou fornecedor agrícola, é possível customizar a plataforma e a análise de risco para as minhas regras de crédito?",
      answer:
        "Sem dúvidas. Nosso motor de crédito é 100% customizável para suas necessidades, fale com nosso time de cientistas de dados e personalize o motor de crédito como quiser."
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
