import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import macHeroScreenshotImageSrc from "images/stats-illustration-safrai.svg";
import SliderCard from "components/cards/ThreeColSlider.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Como nós funcionamos</Subheading>}
        heading={
          <>
            Sua especialidade é cultivar. A nossa é entregar tecnologia <HighlightedText>para o agro negócio.</HighlightedText>
          </>
        }
      />
      <SliderCard/>
      <FeatureWithSteps
        subheading={<Subheading>Como funciona</Subheading>}
        heading={
          <>
            Fácil de<HighlightedText> começar.</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />
      <Testimonial
        subheading={<Subheading>O que falam de nós</Subheading>}
        heading={
          <>
            Nossos clientes <HighlightedText>nos amam.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://pps.whatsapp.net/v/t61.24694-24/206939288_817004542300832_6371923932663218190_n.jpg?ccb=11-4&oh=01_AdTdEo-PA00KqPnqStJDIK8c2D3cVV6C0JaBvo70yBLacA&oe=654E9C5C&_nc_sid=e6ed6c&_nc_cat=105",
            heading: "Velocidade e eficiência para nossos processos.",
            quote:
              "Com a tecnologia da Safraí conseguimos agilizar o processo de ceder crédito para o produtor, cortamos custos para a emissão de CPR e diminuimos o risco para as revendas.",
            customerName: "Anderson Kato",
            customerTitle: "Diretor da GoldenStrat"
          },
          {
            stars: 5,
            profileImageSrc:
              "https://pps.whatsapp.net/v/t61.24694-24/292293472_1678737689160320_4793115511989726828_n.jpg?ccb=11-4&oh=01_AdQ1jYb8NdBgEZQMFzn5oH4qY8RadLzvNhwQ7m2FvOk4Gg&oe=654E7945&_nc_sid=e6ed6c&_nc_cat=101",
            heading: "Crédito na palma da mão.",
            quote:
              "A safra não espera o nosso tempo, e a Safraí conseguiu conduzir toda a minha tomada de crédito do início ao fim, já com emissão da CPR e me conectando com a revenda parceira, tudo em minutos.",
            customerName: "Rafael Barbosa",
            customerTitle: "CEO da Fazenda Cana Brava"
          },
        ]}
      />
      <FAQ
        subheading={<Subheading>Perguntas frequentes</Subheading>}
        heading={
          <>
            Tem alguma <HighlightedText>dúvida?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "O que é a Safraí?",
            answer:
              "Safraí é uma plataforma que concentra todo o ciclo de crédito agro em um único lugar. Aliamos tecnologia com inteligência artificial e mais de 2000 fontes de dados para fazer a melhor análise de crédito com o mínimo de risco, emitimos a CPR para o produtor rural e conectamos com revendedores parceiros para que façam negócios e prosperem juntos. Para as revendas, além da conexão com os produtores, disponibilizamos também a análise de crédito para tomarem a melhor decisão em relação a um cliente. Fornecemos também o monitoramento de garantias e gestão de recebíveis para a revenda focar no que importa: vender. "
          },
          {
            question: "Como funciona?",
            answer:
              "Simples. Faça um cadastro simples para entendermos suas neessidades. Acesse a plataforma e comece a usar."
          },
          {
            question: "É possível tomar crédito sem garantia?",
            answer:
              "No momento, a Safraí auxilia a tomada de crédito com garantia de CPR. As operações barter geralmente são travadas por meio de Cédula de Produto Rural (CPR), o que permite que o agricultor possa retirar rapidamente insumos para aplicação em suas lavouras, sem que isso implique em demorados processos a cada nova compra de produtos. E na Safraí você consegue fazer todo o processo de forma automática e sem enrolação."
          },
          {
            question: "Em quanto tempo meu crédito está liberado?",
            answer:
              "Tudo vai depender se a sua documentação está em dia e em conformidade com os orgãos compententes. Se estiver tudo certo, a análise e emissão de CPR sai em minutos e a liberação acontece no mesmo dia."
          },
          {
            question: "Qual o limite máximo de liberação de crédito na Safraí?",
            answer:
            "Nós não emitimos o crédito, nossa plataforma gera inteligência para a melhor decisão de emissão de crédito e conectamos com fornecedores agrícolas que querem te dar crédito. Se você é produtor você conseguirá avaliar seu perfil e emitir a CPR para ir a mercado comprar insumos para sua produção. Se você é fornecedor agrícola você consegue avaliar seus clientes para tomar a melhor decisão e mitigar riscos. Lembre-se: cada análise de crédito é única, e tudo vai depender da capacidade da sua fazenda de absorver crédito. Portanto a decisão de limite de crédito está com o fornecedor que quer te emprestar."
          },
          {
            question: "Sou fornecedor agrícola, é possível customizar a plataforma e a análise de risco para as minhas regras de crédito?",
            answer:
              "Sem dúvidas. Nosso motor de crédito é 100% customizável para suas necessidades, fale com nosso time de cientistas de dados e personalize o motor de crédito como quiser."
          }
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
}
