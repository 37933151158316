import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithBackground.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <ContactUsForm />
      <ContactDetails
        cards={[
          {
            title: "Goiânia",
            description: (
              <>
                <Address>
                  <AddressLine>Rua 72, sala 801</AddressLine>
                  <AddressLine>Jardim Goiás - Goiânia</AddressLine>
                </Address>
                <Email>go@safrai.com.br</Email>
                <Phone>+55 (62) 4991-6988</Phone>
              </>
            )
          },
          {
            title: "São Paulo",
            description: (
              <>
                <Address>
                  <AddressLine>Avenida Brigadeiro Faria Lima, 4055</AddressLine>
                  <AddressLine>Itaim Bibi - São Paulo</AddressLine>
                </Address>
                <Email>sp@safrai.com.br</Email>
                <Phone>+55 (11) 5991-6988</Phone>
              </>
            )
          },
          
        ]}
      />
      <Footer />
    </AnimationRevealPage>
  );
};
